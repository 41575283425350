import Link from 'components/Globals/Base/Link';
import { NextCustomImage as NextImage } from 'components/Image';
import BylineList from 'components/Byline';
import Heading from 'styles/typography/Heading';
import { ArticlePreview } from 'interfaces/content/articles/Articles';
import useDateTime from 'hooks/useDateTime';
import { EventData } from 'services/Gtm/entities';
import React, { ForwardedRef } from 'react';
import { FontFamily, FontSizes, ResponsiveStyling } from 'styles/theme/types';
import * as S from './ArticleCardStyles';

export type Variant = 'hero' | 'card-sm' | 'card-md' | 'card-lg' | 'card-xl';

type CustomFontSizes = Omit<FontSizes, 'xlarge' | 'xxlarge'>;

interface Props {
  article: ArticlePreview;
  isRow?: boolean;
  variant?: Variant;
  className?: string;
  trackingData: EventData;
  titleSize?: ResponsiveStyling<keyof CustomFontSizes>;
  titleFontFamily?: FontFamily;
  eyebrowType?: 'brandAccentPrimary' | 'red';
  priority?: boolean;
}

const ArticleCard = (props: Props, ref: ForwardedRef<HTMLDivElement> | null) => {
  const {
    article,
    trackingData,
    variant = 'card-md',
    isRow = false,
    className = '',
    titleFontFamily,
    titleSize,
    eyebrowType = 'brandAccentPrimary',
    priority = false,
  } = props;

  const localDate = useDateTime(article.date ? article.date : '', 'date');
  const headingAs = variant === 'hero' ? 'h2' : 'h3';

  function predefinedTitleSize(vari: Variant): ResponsiveStyling<keyof CustomFontSizes> {
    switch (vari) {
      case 'hero':
        return { default: 'level4', mdMin: 'level2' };
      case 'card-sm':
        return { default: 'level6', mdMin: 'level6' };
      case 'card-md':
        return { default: 'level5', mdMin: 'level5' };
      case 'card-lg':
        return { default: 'level5', mdMin: 'level4' };
      case 'card-xl':
        return { default: 'level4', mdMin: 'level3' };
      default:
        return { default: 'level2', mdMin: 'level2' };
    }
  }

  function predefinedTitleFontFamily(vari: Variant, row: boolean): FontFamily {
    switch (vari) {
      case 'hero':
      case 'card-xl':
      case 'card-lg':
        return 'saol';
      case 'card-md':
        return row ? 'graphikCond' : 'saol';
      case 'card-sm':
        return 'graphikCond';
      default:
        return 'graphikCond';
    }
  }

  return (
    <S.Container
      $variant={variant}
      $isRow={isRow}
      className={`${className} ${variant}`}
      ref={ref}
    >
      {!!article.image?.mediaItemUrl && (
        <Link
          href={article.titleLink}
          type='image'
          className='card-image'
          trackingData={
            trackingData && {
              ...trackingData,
              eventLabel: `image click : ${trackingData.eventLabel || article.titleLink}`,
            }
          }
        >
          <NextImage
            width={300}
            height={300}
            src={article.image?.mediaItemUrl}
            alt={article.image?.altText ? article.image?.altText : article.title}
            className='image-link'
            priority={priority}
          />
        </Link>
      )}
      <div className='card-wrapper'>
        {article.eyebrowSectionLabel &&
          article.eyebrowSectionLink &&
          article.eyebrowSectionLabel !== 'No Primary Section Found' && (
          <Link
            href={article.eyebrowSectionLink}
            type={eyebrowType}
            className='card-eyebrow'
            ariaLabel={`Go to ${article.eyebrowSectionLabel}`}
            trackingData={
              trackingData && {
                ...trackingData,
                eventCategory: 'navigation',
                eventLabel: `category click : ${article.eyebrowSectionLabel}`,
              }
            }
          >
            {article.eyebrowSectionLabel}
          </Link>
        )}

        <Heading
          as={headingAs}
          $fontFamily={titleFontFamily || predefinedTitleFontFamily(variant, isRow)}
          $size={titleSize || predefinedTitleSize(variant)}
          className='card-title'
        >
          <Link
            href={article.titleLink}
            className='title-link'
            type='primary'
            trackingData={
              trackingData && {
                ...trackingData,
                eventCategory: 'navigation',
                eventLabel: `title click : ${trackingData.eventLabel || article.title}`,
              }
            }
          >
            {article.title}
          </Link>
        </Heading>

        {article.authors && article.authors.length > 0 && (
          <BylineList
            className='card-authors'
            bylineList={article.authors}
            isArticle
          />
        )}

        {article.date && <span className='card-date'>{localDate}</span>}
      </div>
    </S.Container>
  );
};

export default React.forwardRef(ArticleCard);
