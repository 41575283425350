import styled from 'styled-components';
import { font, bkptVal, breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';
import { imageAndTitleLinkHover } from 'styles/imageAndTitleLinkHover';
import type { Variant } from '.';

function imageAspectRatio(variant: Variant, isRow: boolean) {
  if (isRow) {
    return '1.51/1';
  }
  switch (variant) {
    case 'hero':
    case 'card-xl':
    case 'card-lg':
    case 'card-sm':
      return '3/2';
    case 'card-md':
      return '16/9';
    default:
      return '16/9';
  }
}

/* eslint-disable import/prefer-default-export */
export const Container = styled.div<{ theme: Theme; $variant: Variant; $isRow: boolean }>`

  container: cardContainer/inline-size;
  ${imageAndTitleLinkHover}

  width: 100%;
  display: flex;
  flex-direction: ${({ $isRow }) => ($isRow ? 'row' : 'column')};
  
  gap: ${({ $isRow }) => ($isRow ? '16px' : '0px')};

  .card-wrapper {
    display: flex;
    flex-direction: column;
  }

  .card-image {
    max-width: ${({ $isRow }) => ($isRow ? '30%' : '100%')};
    margin-bottom: ${({ $isRow, $variant }) => !$isRow && (($variant === 'card-sm' && '8px') || '16px')};
    img {
      aspect-ratio: ${({ $variant, $isRow }) => imageAspectRatio($variant, $isRow)};
      object-fit: cover;
    } 
}

  .card-eyebrow {
    font-family: ${font.graphikCond};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 150%;
    text-transform: uppercase;
    position: relative;
    margin-bottom: ${({ $variant }) => ($variant === 'hero' ? '16px' : '8px')};

  }

  .card-title {
    margin-bottom: 16px;
  }

  &.compact .card-title {
    margin-bottom: ${({ $variant }) => (['card-xl', 'card-sm'].indexOf($variant) > -1 ? '8px' : '16px')};
  }

  .card-authors {
    margin-top: auto;
    line-height: 120%;
  }

  .card-date {
    color: ${getColor('textSecondary')};
    font-family: ${font.graphikCond};
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.17px;
    line-height: 21px;
  }
  @media (${breakpoint.mdMax}) {
    &.compact .card-title{
      margin-bottom: 16px;
    }
  }
    
  @container cardContainer (min-width: ${bkptVal.sm}rem){
    .card-image {
      margin-bottom: ${({ $variant, $isRow }) => !$isRow && ($variant === 'hero' ? '24px' : '16px')};
    }
  }
`;
