import { useEffect, useState } from 'react';
import useIsBrowser from './useIsBrowser';

export type DateTimeFormat = 'date' | 'datetime';

/**
 * Replaces space in the middle of timestamp string, if it exists, with a 'T'.
 *
 * @param timestamp 'YYYY-MM-DD 00:00:00Z' | 'YYYY-MM-DDT00:00:00Z'
 * @returns string 'YYYY-MM-DDT00:00:00Z'
 */
const standardizeTimestamp = (timestamp: string): string => timestamp.replace(/ /g, 'T');

/**
 * Standardizes timestamp string and then formats it to display readable
 * date or date and time based on fmt param.
 *
 * @param timestamp 'YYYY-MM-DD 00:00:00Z' | 'YYYY-MM-DDT00:00:00Z'
 * @param fmt 'datetime' | 'date'
 * @returns '00 Month, YYYY, 00:00 AM/PM EST' | '00 Month, YYYY'
 */
const format = (timestamp: string, fmt: DateTimeFormat): string => {
  const standardizedStamp = standardizeTimestamp(timestamp);
  let formattedTimeStamp = '';

  switch (fmt) {
    case 'datetime':
      formattedTimeStamp = new Date(standardizedStamp).toLocaleString('en-US', {
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        month: 'long',
        timeZoneName: 'short',
        year: 'numeric',
      });
      break;
    case 'date':
      formattedTimeStamp = new Date(standardizedStamp).toLocaleString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
      break;
    default:
      break;
  }

  return formattedTimeStamp;
};

/**
 * NOTE: dateGmt should always be in GMT/UTC time, do NOT pass in local timestamp.
 * Appending the 'Z' to the end of the string indicates that it is in GMT/UTC time.
 *
 * @param dateGmt 'YYYY-MM-DD 00:00:00' | 'YYYY-MM-DDT00:00:00'
 * @param fmt 'datetime' | 'date'
 * @returns '00 Month, YYYY, 00:00 AM/PM EST' | '00 Month, YYYY'
 */

export default function useDateTime(dateGmt: string, fmt: DateTimeFormat = 'datetime'): string | null {
  const isBrowser = useIsBrowser();

  const [dateTime, setDateTime] = useState<string | null>(null);

  useEffect(() => {
    setDateTime(format(`${dateGmt}Z`, fmt));
  }, [dateGmt, fmt, isBrowser]);

  return dateTime;
}
