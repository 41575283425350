import styled from 'styled-components';
import { font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';
import { bluePrimaryLink } from 'components/Globals/Base/Link/LinkStyles';

export const ListWrapper = styled.div`
  &, &.author-list{
    font-size: 14px;
    letter-spacing: 1.2px;
    line-height: 21px;
    margin: 0px;
    text-transform: uppercase;

    &.article-info {
      span a {
        text-decoration: underline;
      }
    }
  }
`;
export const DescriptionWrapper = styled.div<{ theme: Theme }>`
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 4px 0;
  letter-spacing: 0.5px;

  a {
    ${bluePrimaryLink}
  }
`;

export const ByWrapper = styled.span<{ theme: Theme }>`
  &, &.by-text {
    font-family: ${font.graphikCond};
    font-weight: inherit;
    color: ${getColor('textPrimary')};
    margin: 0px 4px 0px 0px;
  }
`;

export const ListItemWrapper = styled.span`
  &, &.list-item-wrapper {
    font-family: ${font.graphikCond};
    font-weight: 600;
  
    > a {
      position: relative;
      z-index: 2;
    }
  }
`;

export const ListItemSeperator = styled.span`
  &, &.list-item-seperator {
    font-family: ${font.graphikCond};
    font-weight: 400;
  }
`;
