import { Author } from 'interfaces/content/Authors';
import Link from 'components/Globals/Base/Link';
import { EventData } from 'services/Gtm/entities';
import { GridAuthorWithUri } from 'interfaces/content/Ranking';
import { Fragment } from 'react';
import RawHtml from 'utils/miscUtils/rawHtml';
import * as S from './BylineListStyles';

export interface BylineListProps {
  bylineList: Author[] | GridAuthorWithUri[];
  trackingData?: EventData;
  nativoClassName?: string;
  isArticle?: boolean;
  className?: string;
  title?: string;
  description?: string;
  dataCy?: string;
}

const BylineList = ({
  bylineList,
  isArticle = false,
  className,
  nativoClassName,
  trackingData,
  title,
  description,
  dataCy,
}: BylineListProps) => {
  const bylineListLength: number = bylineList.length;
  const href = title === 'Most Popular' ? `${bylineList[0]?.uri}/` : bylineList[0]?.uri;

  if (bylineListLength === 1) {
    return (
      <S.ListWrapper
        className={className}
        data-cy={dataCy}
      >
        {nativoClassName ? null : <S.ByWrapper className='by-text'>BY</S.ByWrapper>}
        <S.ListItemWrapper
          key={bylineList[0].name}
          className={nativoClassName || 'list-item-wrapper'}
        >
          <Link
            ariaLabel={`Go to ${bylineList[0].name} author's page`}
            href={href}
            rel='author'
            trackingData={
              trackingData && {
                ...trackingData,
                eventLabel: `${bylineList[0].name} : ${trackingData.eventLabel}`,
              }
            }
            type='secondary'
          >
            {bylineList[0].name}
          </Link>
        </S.ListItemWrapper>
        {description && <S.DescriptionWrapper>{RawHtml({ html: description })}</S.DescriptionWrapper>}
      </S.ListWrapper>
    );
  }

  if (bylineListLength >= 2 && isArticle) {
    return (
      <S.ListWrapper
        className={className}
        data-cy={dataCy}
      >
        <S.ByWrapper>BY</S.ByWrapper>
        {bylineList.map((author: { name: string; uri: string }, idx) => (
          <Fragment key={author.uri}>
            <S.ListItemWrapper key={author.name}>
              <Link
                ariaLabel={`Go to ${author.name} author's page`}
                href={title === 'Most Popular' ? `${author.uri}/` : author.uri}
                trackingData={
                  trackingData && {
                    ...trackingData,
                    eventLabel: `${author.name} : ${trackingData.eventLabel}`,
                  }
                }
                type='secondary'
              >
                {author.name}
              </Link>
            </S.ListItemWrapper>
            <S.ListItemSeperator>
              {bylineListLength === 2 && idx === 0 && ' and '}
              {bylineListLength > 2 && bylineListLength - 2 > idx && ', '}
              {bylineListLength > 2 && bylineListLength - 2 === idx && ' and '}
            </S.ListItemSeperator>
          </Fragment>
        ))}
        {description && <S.DescriptionWrapper>{RawHtml({ html: description })}</S.DescriptionWrapper>}
      </S.ListWrapper>
    );
  }

  if (bylineListLength >= 2) {
    return (
      <S.ListWrapper
        className={className}
        data-cy={dataCy}
      >
        <S.ByWrapper className='by-text'>BY</S.ByWrapper>
        <S.ListItemWrapper
          key={bylineList[0].name}
          className='list-item-wrapper'
        >
          <Link
            ariaLabel={`Go to ${bylineList[0].name} author's page`}
            href={href}
            trackingData={
              trackingData && {
                ...trackingData,
                eventLabel: `${bylineList[0].name} : ${trackingData.eventLabel}`,
              }
            }
            type='secondary'
          >
            {bylineList[0].name}
          </Link>
        </S.ListItemWrapper>
        <S.ListItemSeperator className='list-item-seperator'>
          {bylineListLength === 2 ? ' and ' : ', '}
        </S.ListItemSeperator>
        <S.ListItemWrapper
          className='list-item-wrapper'
          key={bylineList[1].name}
        >
          <Link
            ariaLabel={`Go to ${bylineList[1].name} author's page`}
            href={title === 'Most Popular' ? `${bylineList[1].uri}/` : bylineList[1].uri}
            trackingData={
              trackingData && {
                ...trackingData,
                eventLabel: `${bylineList[1].name} : ${trackingData.eventLabel}`,
              }
            }
            type='secondary'
          >
            {bylineList[1].name}
          </Link>
        </S.ListItemWrapper>
        {bylineListLength > 2 && (
          <S.ListItemSeperator className='list-item-seperator'>, and others</S.ListItemSeperator>
        )}
      </S.ListWrapper>
    );
  }

  return null;
};

export default BylineList;
